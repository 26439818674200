import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

import CenteredTypography from './CenteredTypography'
import GridCenter from './GridCenter'
import GridItem from './GridItem'
import GridItemContactUs from './GridItemContactUs'
import GridImage from './GridImage'
import ApiClient from '../client/ApiClient'
import Timeout from '../utility/Timeout'
import TransitionTime from '../utility/TransitionTime'

export default function FindStatement ({ passedVisitId, setPhoneNumberLastFour }) {
  const [visitId, setVisitId] = useState(passedVisitId || '')
  const [fadeIn, setFadeIn] = useState(true)
  const [error, setError] = useState('')
  const isInternalId = passedVisitId && passedVisitId.length === 36
  const { t } = useTranslation()

  const handleVisitIdChange = (newValue) => {
    newValue = newValue.replace(/\D/g, '')

    setVisitId(newValue)
  }

  const handleNext = async () => {
    setFadeIn(false)

    const response = isInternalId
      ? await ApiClient.checkWithId(visitId)
      : await ApiClient.checkWithDocutapVisitId(visitId)

    if(response.error) {
      setError(response.error)
      setFadeIn(true)

      return
    }

    await Timeout(TransitionTime)
    setPhoneNumberLastFour(response.phoneNumberLastFour)
  }

  return (
    <Fade in={fadeIn} timeout={TransitionTime}>
      <Grid container>
        <GridItem>
          <GridImage name='MainStreet.png' />
        </GridItem>
        <GridItem>
          <GridImage name='KidsStreet.png' />
        </GridItem>
        { !isInternalId &&
          <>
            <GridItem>
              <br />
              <CenteredTypography>{t('payPortal.enterVisitId')}</CenteredTypography>
            </GridItem>
            <GridItem>
              <GridCenter width={4}>
                <TextField
                  fullWidth
                  inputProps={{ inputMode: 'numeric' }}
                  value={visitId}
                  onChange={(e) => handleVisitIdChange(e.target.value)} />
              </GridCenter>
            </GridItem>
          </>
        }
        { isInternalId &&
          <>
            <GridItem>
              <br />
              <CenteredTypography>{t('payPortal.verifyPhoneNumber')}</CenteredTypography>
            </GridItem>
          </>
        }
        {error &&
          <GridItem>
            <CenteredTypography>{error}</CenteredTypography>
          </GridItem>
        }
        <GridItem>
          <br />
          <GridCenter width={2}>
            <Button disabled={!visitId} fullWidth variant='contained' onClick={() => handleNext()}>{t('payPortal.next')}</Button>
          </GridCenter>
        </GridItem>
        <GridItemContactUs />
      </Grid>
    </Fade>
  )
}