import CenteredTypography from './CenteredTypography'
import GridItem from './GridItem'
import { useTranslation } from 'react-i18next'

const billingNumber = '256-854-9989'

export default function GridItemContactUs() {
  const { t } = useTranslation()

  return (
    <GridItem>
      <br />
      <CenteredTypography variant='h6'>{t('payPortal.questionsAboutBill')}</CenteredTypography>
      <CenteredTypography variant='h6'>{t('payPortal.callUs')} <a href={`tel:${billingNumber}`}>{billingNumber}</a></CenteredTypography>
    </GridItem>
  )
}