import { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function ConfirmCardDialog({ isLoading, gotError, externalIsOpen, cardNumberLastFour, handleSubmit }) {
  const [isOpen, setIsOpen] = useState(false)
  const [showError, setShowError] = useState(false)
  const { t } = useTranslation()
  
  useEffect(() => {
    setIsOpen(!!externalIsOpen)
  }, [externalIsOpen])

  useEffect(() => {
    setShowError(gotError)
  }, [gotError])

  const handleClose = () => setIsOpen(false)

  return (
    <Dialog maxWidth='xs' fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {t('payPortal.payment.confirm')}
      </DialogTitle>
      <DialogContent>
        <Typography>{t('payPortal.payment.payWithCard', { lastFour: cardNumberLastFour })}</Typography>
        {showError && <Typography color='error'>{t('payPortal.payment.error')}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('payPortal.payment.no')}</Button>
        <Button disabled={isLoading} variant='contained' onClick={handleSubmit}>{t('payPortal.payment.yes')}</Button>
      </DialogActions>
    </Dialog>
  )
}