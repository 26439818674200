import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { uniq, lowerCase } from "lodash";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function DenialCodeMessage({ denialCodes, balance }) {
  const { t } = useTranslation();
  const [isDenialCodeDetailsExpanded, setIsDenialCodeDetailsExpanded] =
    useState(false);

  const distinctDenialCodes = 
    uniq(denialCodes)
    .map((code) => {
        const key = `payPortal.denial.${lowerCase(code)}`
        const description = t(key)

        if(!description || description === key) {
            return null;
        }
        return { code, description };
    })
    .filter(dc => dc != null);

  const handleDenialCodeDetailsExpand = (e, expanded) => {
    setIsDenialCodeDetailsExpanded(expanded);
  };

  const messageStyles = {
    border: 3,
    borderColor: "message.contrastText",
    borderRadius: 2,
    marginTop: 2,
    marginBottom: 2,
    backgroundColor: "message.main",
  };

  const messageStylesCollapsed = {
    ...messageStyles,
    backgroundColor: "initial",
  };

  if (!distinctDenialCodes || !distinctDenialCodes.length) {
    return null;
  }

  return (
    <Box>
      <Box sx={{ ...messageStyles, padding: 2 }}>
        <Typography
          sx={{ fontWeight: "bold" }}
          variant="body1"
          color="message.contrastText"
        >
          {t('payPortal.denial.importantMessage')}
        </Typography>
        <Typography variant="body1" color="message.contrastText">
          {t('payPortal.denial.sorry')}
          <Box component="span" fontWeight="bold">
            {` ${balance}`}
          </Box>
          {"."}
        </Typography>
        <br />
        <Typography variant="body1" color="message.contrastText">
          {`${t('payPortal.denial.reasons')} `}
          {distinctDenialCodes.map((denialCode, index) => {
            if (!denialCode) {
              return null;
            }
            return (
              <Box
                component="span"
                key={index}
                sx={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setIsDenialCodeDetailsExpanded(true)}
              >
                {denialCode.code}
                {index < distinctDenialCodes.length - 1 ? ", " : ""}
              </Box>
            );
          })}
          {"."}
        </Typography>
      </Box>
      <Accordion
        expanded={isDenialCodeDetailsExpanded}
        onChange={handleDenialCodeDetailsExpand}
        sx={
          isDenialCodeDetailsExpanded ? messageStyles : messageStylesCollapsed
        }
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{ color: "message.contrastText" }}
              fontSize="large"
            />
          }
        >
          <Typography variant="body1" color="message.contrastText">
            {t('payPortal.denial.readMore')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {distinctDenialCodes.map((denialCode, index) => {
              if (!denialCode) {
                return null;
              }

              return (
                <Box key={index} sx={{ marginBottom: 2 }}>
                  <Typography
                    sx={{ fontWeight: "bold" }}
                    variant="body1"
                    color="message.contrastText"
                  >
                    {t('payPortal.denial.codeMeaning', { code: denialCode.code })}
                  </Typography>
                  <Typography variant="body1">
                    {denialCode.description}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
