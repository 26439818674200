import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom'
import AppRoutes from './AppRoutes'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { I18nextProvider } from 'react-i18next'
import i18n from './page/utility/i18n'
import './App.css'

export default class App extends Component {
  static displayName = App.name

  render() {
    const theme = createTheme({
      palette: {
        primary: {
          main: '#355A99'
        },
        message: {
          main: '#DBE9FE',
          contrastText: '#1C4ED8'
        }
      }
    })

    return (
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <Routes>
            {AppRoutes.map((route, index) => {
              const { element, ...rest } = route
              return <Route key={index} {...rest} element={element} />
            })}
          </Routes>
        </ThemeProvider>
      </I18nextProvider>
    )
  }
}