import { Fade, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import 'react-credit-cards-2/es/styles-compiled.css'

import GridItem from './component/GridItem'
import GridItemContactUs from './component/GridItemContactUs'
import CenteredTypography from './component/CenteredTypography'
import GridImage from './component/GridImage'
import TransitionTime from './utility/TransitionTime'

export default function Home() {
  const { clinicType } = useParams()
  const { t } = useTranslation()

  return (
    <Fade in={true} timeout={TransitionTime}>
      <Grid container>
        <GridItem>
          <GridImage name={clinicType === 'MainStreet' ? 'MainStreet.png' : 'KidsStreet.png'}/>
        </GridItem>
        <GridItem>
          <CenteredTypography variant='h6'><br />{t('payPortal.complete.thankYou')}</CenteredTypography>
          <CenteredTypography variant='h6'>{t('payPortal.complete.welcomeBack')}</CenteredTypography>
          <CenteredTypography variant='h6'>{t('payPortal.complete.feelBetter')}</CenteredTypography>
        </GridItem>
        <GridItemContactUs />
      </Grid>
    </Fade>
  )
}