import { useState } from 'react'
import {
    Button,
    Fade,
    Grid,
    TextField } from '@mui/material'

import CenteredTypography from './CenteredTypography'
import GridCenter from './GridCenter'
import GridItem from './GridItem'
import GridItemContactUs from './GridItemContactUs'
import GridImage from './GridImage'
import ApiClient from '../client/ApiClient'
import Timeout from '../utility/Timeout'
import TransitionTime from '../utility/TransitionTime'
import { useTranslation } from 'react-i18next'

export default function VerifyPhoneNumber ({ phoneNumberLastFour, setInfo }) {
  const [code, setCode] = useState('')
  const [fadeIn, setFadeIn] = useState(true)
  const [error, setError] = useState('')
  const isValid = code && code.length === 4
  const { t } = useTranslation()

  const handleEnter = (e) => {
    if(e.key === 'Enter') {
      e.preventDefault()

      if(isValid) {
        handleNext()
      }
    }
  }

  const handleCodeChange = (newValue) => {
    newValue = newValue.replace(/\D/g, '')
    newValue = newValue.substring(0, 4)

    setCode(newValue)
  }

  const handleNext = async () => {
    setFadeIn(false)

    const response = await ApiClient.verifyPhoneNumber(code)

    if(response.error) {
      setError(response.error)
      setFadeIn(true)

      return
    }

    await Timeout(TransitionTime)
    setInfo(response)
  }

  return (
    <Fade in={fadeIn} timeout={TransitionTime}>
      <Grid container>
        <GridItem>
          <GridImage name='MainStreet.png' />
        </GridItem>
        <GridItem>
          <GridImage name='KidsStreet.png' />
        </GridItem>
        <GridItem>
          <br />
          <CenteredTypography>{t('payPortal.sentAuthCode', { phoneNumber: phoneNumberLastFour })}</CenteredTypography>
        </GridItem>
        <GridItem>
          <br />
          <CenteredTypography>{t('payPortal.enterAuthCode')}</CenteredTypography>
        </GridItem>
        <GridItem>
          <GridCenter width={4}>
            <TextField
              fullWidth
              inputProps={{ inputMode: 'numeric' }}
              value={code}
              onKeyDown={(e) => handleEnter(e)}
              onChange={(e) => handleCodeChange(e.target.value)} />
          </GridCenter>
        </GridItem>
        {error &&
          <GridItem>
            <CenteredTypography>{error}</CenteredTypography>
          </GridItem>
        }
        <GridItem>
          <br />
          <GridCenter width={2}>
            <Button disabled={!isValid} fullWidth variant='contained' onClick={() => handleNext()}>{t('payPortal.verify')}</Button>
          </GridCenter>
        </GridItem>
        <GridItemContactUs />
      </Grid>
    </Fade>
  )
}